<template>
  <div class="container">
    <app-bar
      title="Konsultasi"
      previousLink="/" />

    <div class="card-info p-3 bd-highlight d-flex flex-row align-items-start bg-blue-soft-pastel border-0 text-primary">
      <i class="ri-information-line ri-xl mt-2"></i>
      <div class="ms-2">
        <h5>Segera Hadir</h5>
        <p class="mb-0">
          Pasien bisa berkonsultasi secara langsung dengan dokter Parahita.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { nextTick, onMounted } from '@vue/runtime-core'
import { title } from '@/core/page'
export default {
  components: { AppBar },
  setup () {
    onMounted(() => {
      nextTick(() => {
        title.value = 'Konsultasi'
      })
    })
  }
}
</script>
